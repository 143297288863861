export const reHierarchyLevelReReportConstants = {
	CreateLevelReReport: {
		FAILURE : "CREATE_REHIERARCHYLEVELREREPORT_FAILURE",
		REQUEST : "CREATE_REHIERARCHYLEVELREREPORT_REQUEST",
		SUCCESS : "CREATE_REHIERARCHYLEVELREREPORT_SUCCESS"
	},
	CreateLevelReReports: {
		FAILURE : "CREATE_REHIERARCHYLEVELREREPORTS_FAILURE",
		REQUEST : "CREATE_REHIERARCHYLEVELREREPORTS_REQUEST",
		SUCCESS : "CREATE_REHIERARCHYLEVELREREPORTS_SUCCESS"
	},
	DeleteLevelReReport: {
		FAILURE : "DELETE_REHIERARCHYLEVELREREPORT_FAILURE",
		REQUEST : "DELETE_REHIERARCHYLEVELREREPORT_REQUEST",
		SUCCESS : "DELETE_REHIERARCHYLEVELREREPORT_SUCCESS"
	},
	DeleteLevelReReports: {
		FAILURE : "DELETE_REHIERARCHYLEVELREREPORTS_FAILURE",
		REQUEST : "DELETE_REHIERARCHYLEVELREREPORTS_REQUEST",
		SUCCESS : "DELETE_REHIERARCHYLEVELREREPORTS_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REHIERARCHYLEVELREREPORT_ALL_PAGED",
		FAILURE : "GET_REHIERARCHYLEVELREREPORT_All_PAGED_FAILURE",
		REQUEST : "GET_REHIERARCHYLEVELREREPORT_All_PAGED_REQUEST",
		SUCCESS : "GET_REHIERARCHYLEVELREREPORT_All_PAGED_SUCCESS"
	},
	GetAllPagedExpand: {
		FAILURE : "GET_REHIERARCHYLEVELREREPORT_EXPAND_All_PAGED_FAILURE",
		REQUEST : "GET_REHIERARCHYLEVELREREPORT_EXPAND_All_PAGED_REQUEST",
		SUCCESS : "GET_REHIERARCHYLEVELREREPORT_EXPAND_All_PAGED_SUCCESS"
	},
	UpdateLevelReReport: {
		FAILURE : "UPDATE_REHIERARCHYLEVELREREPORT_FAILURE",
		REQUEST : "UPDATE_REHIERARCHYLEVELREREPORT_REQUEST",
		SUCCESS : "UPDATE_REHIERARCHYLEVELREREPORT_SUCCESS"
	}
}