import React, {
	useEffect, useState
}from "react"
import {
	Modal, Form, Empty, Select, Spin, Checkbox
}from "antd"
import {
	reOrgActions
}from "../ReOrgs/actions"
import {
	useDebounce
}from "use-lodash-debounce"
import i18nGlobal from "../../../localization"
import i18n from "./localization"
import {
	useDispatch, useSelector
}from "react-redux"
import "antd/lib/empty/style/index.css"
import "antd/lib/spin/style/index.css"
import {
	CustomSelect
}from '../../../components/CustomSelect'
import CustomButton from '../../../components/ButtonCustom'

const ReOrgReportReOrgCreateForm = (props) => {
	const dispatch = useDispatch()
	const isRu = useSelector(state => state.globalReducer.isRu)
	const { visible, setVisible, orgId, create, loading } = props
	const [form] = Form.useForm()
	const [orgName, setOrgName] = useState("")
	const [values, setValues] = useState([])
	const searchOrg = useDebounce(orgName, 500)
	const {
		minReOrgByNameOrUNP,
		minReOrgSending
	} = useSelector((state) => state.reOrgReducer)

	useEffect(() => {
		if (visible)
			getReOrgsByName(searchOrg)
	}, [searchOrg])

	function getReOrgsByName(name){
		if (name?.length > 2){
			dispatch(reOrgActions.getMinReOrgByNameOrUNP({
				name     : name,
				pageSize : 10
			}))
		}
	}

	function handleCancel(){
		form.resetFields()
		setVisible(false)
		setValues([])
		setOrgName("")
		dispatch(reOrgActions.clearMinReOrgByNameOrUNP())
	}

	useEffect(() => {
		form.setFieldsValue({ reOrgs: values })
	}, [values])

	function handleSelectOrgs(id){
		const selectedOrg = minReOrgByNameOrUNP?.find((value) => {
			return value.id == id
		})
		if (values)
			setValues([...values, selectedOrg])
		else
			setValues([selectedOrg])
	}

	function handleChangeOrgs(ids){
		if (ids.length == 0)
			setValues([])
	}

	function handleDeselectOrgs(id){
		if (id){
			const selectedValues = values.filter((org) => {
				return org.id != id
			})
			setValues(selectedValues)
		}
	}

	const onFinish = values => {
		create({
			institution : values.institution,
			parent      : values.parent,
			reOrgId     : orgId,
			reOrgs      : values.reOrgs?.map((x) => x.id) ?? []
		})
		handleCancel()
	}

	return (
		<div>
			<Modal
				destroyOnClose
				footer={ null }
				title={ i18n.headerCreateForm[isRu] }
				visible={ visible }
				width={ 800 }
				onCancel={ handleCancel }
			>
				<Form
					form={ form }
					id="mainAddReOrgReportReOrgForm"
					layout="vertical"
					onFinish={ onFinish }
				>
					<Form.Item
						label={ i18n.createFormOrgsLabel[isRu] }
						name="reOrgs"
						tooltip={ { title: i18n.inputSearch[isRu] } }
					>
						<>
							<CustomSelect
								allowClear
								showSearch
								defaultActiveFirstOption={ false }
								disabled={ loading }
								filterOption={ false }
								maxTagCount={ 7 }
								mode="multiple"
								notFoundContent={
									minReOrgSending ? <Spin size="small" /> : <Empty description={ i18n.selectOrgEmpty[isRu] } />
								}
								placeholder={ i18n.createFormOrgsLabel[isRu] }
								style={ { height: "auto" } }
								value={ values ? values.map((x) => x.id) : [] }
								onChange={ handleChangeOrgs }
								onDeselect={ handleDeselectOrgs }
								onSearch={ (value) => setOrgName(value) }
								onSelect={ handleSelectOrgs }
							>
								{ minReOrgByNameOrUNP?.map((value) => {
									return (
										<Select.Option
											key={ value.id }
											title={ isRu || !value.fullNameBel ? value.fullName : value.fullNameBel }
											value={ value.id }
										>
											{ isRu || !value.fullNameBel ? value.fullName : value.fullNameBel }
										</Select.Option>
									)
								}) }
							</CustomSelect>
						</>
					</Form.Item>
					<Form.Item
						label={ i18n.isParent[isRu] }
						name="parent"
						valuePropName="checked"
					>
						<Checkbox disabled={ loading } />
					</Form.Item>
					<Form.Item
						label={ i18n.isInstitution[isRu] }
						name="institution"
						valuePropName="checked"
					>
						<Checkbox disabled={ loading } />
					</Form.Item>
					<Form.Item>
						<CustomButton
							htmlType="submit"
							loading={ loading }
							style={ { float: "right" } }
							type="orange"
						>
							{ i18nGlobal.save[isRu] }
						</CustomButton>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	)
}
export default ReOrgReportReOrgCreateForm
