import {
	reAppealTypesConstants
}from './constants'

import i18n from "../localization"
import isRu from "../../../helpers/isRu"

const initialState =
{
	isSending         : false,
	currentItem       : { case1: '12313' },
	appealTypeChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reAppealTypesConstants.SEND_REAPPEALTYPES_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reAppealTypesConstants.SEND_REAPPEALTYPES_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload

			}
		case reAppealTypesConstants.SEND_REAPPEALTYPES_FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reAppealTypesConstants.CreateAppealTypes.REQUEST:
		case reAppealTypesConstants.DeleteAppealTypes.REQUEST:
		case reAppealTypesConstants.RestoreAppealTypes.REQUEST:
		case reAppealTypesConstants.UpdateAppealTypes.REQUEST:
			return {
				...state,
				isSending         : true,
				appealTypeChanged : null
			}
		case reAppealTypesConstants.CreateAppealTypes.FAILURE:
		case reAppealTypesConstants.DeleteAppealTypes.FAILURE:
		case reAppealTypesConstants.RestoreAppealTypes.FAILURE:
		case reAppealTypesConstants.UpdateAppealTypes.FAILURE:
			return {
				...state,
				isSending         : false,
				error             : action.payload,
				appealTypeChanged : null
			}
		case reAppealTypesConstants.CreateAppealTypes.SUCCESS:
			return {
				...state,
				isSending         : false,
				appealTypeChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reAppealTypesConstants.DeleteAppealTypes.SUCCESS:
			return {
				...state,
				isSending         : false,
				appealTypeChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reAppealTypesConstants.UpdateAppealTypes.SUCCESS:
			return {
				...state,
				isSending         : false,
				appealTypeChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reAppealTypesConstants.RestoreAppealTypes.SUCCESS:
			return {
				...state,
				isSending         : false,
				appealTypeChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reAppealTypesConstants.SEND_REAPPEALTYPES_CLEAR:
			return {
				...state,
				appealTypeChanged : null,
				isSending         : false,
				currentItem       : {}

			}
		default:
			return state
	}
}