import {
	reOrgReportReOrgConstants
}from "./constants"
import {
	defAction
}from "../../../../helpers/defaultAction"
import api from "./api"

export const reOrgReportReOrgActions = {
	clear,
	createReOrgReportReOrgs,
	deleteReOrgReportReOrg,
	getAllPaged,
	getAllReOrgs,
	isAnyReOrgs
}

function clear(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: reOrgReportReOrgConstants.GetAllPaged.CLEAR
			}
		}
	}
}

function createReOrgReportReOrgs(values){
	const dispatchObj = {
		constants : reOrgReportReOrgConstants.CreateReOrgReportReOrgs,
		service   : {
			func   : api.createReOrgReportReOrgs,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteReOrgReportReOrg(values){
	const dispatchObj = {
		constants : reOrgReportReOrgConstants.DeleteReOrgReportReOrg,
		service   : {
			func   : api.deleteReOrgReportReOrg,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllPaged(values){
	const dispatchObj = {
		constants : reOrgReportReOrgConstants.GetAllPaged,
		service   : {
			func   : api.getAllPaged,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllReOrgs(values){
	const dispatchObj = {
		constants : reOrgReportReOrgConstants.GetAllReOrgs,
		service   : {
			func   : api.getAllReOrgs,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function isAnyReOrgs(){
	const dispatchObj = {
		constants : reOrgReportReOrgConstants.IsAnyReOrgs,
		service   : {
			func: api.isAnyReOrgs
		}
	}
	return defAction(dispatchObj)
}