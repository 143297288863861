import {
	reOrgReReportConstants
}from "./constants"
import {
	defAction
}from "../../../../helpers/defaultAction"
import api from "./api"

export const reOrgReReportActions = {
	clear,
	clearFile,
	createReOrgReReport,
	createReOrgReReports,
	deleteReOrgReReport,
	deleteReOrgReReports,
	getActualKindsReports,
	getAllPaged,
	getAllReports,
	updateReOrgReReport,
	uploadReportLevelsFromFile,
	uploadReportOrgsFromFile
}

function clear(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: reOrgReReportConstants.GetAllPaged.CLEAR
			}
		}
	}
}

function clearFile(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: reOrgReReportConstants.UploadFromFile.CLEAR
			}
		}
	}
}

function createReOrgReReports(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.CreateReOrgReReports,
		service   : {
			func   : api.createReOrgReReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function createReOrgReReport(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.CreateReOrgReReport,
		service   : {
			func   : api.createReOrgReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteReOrgReReport(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.DeleteReOrgReReport,
		service   : {
			func   : api.deleteReOrgReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteReOrgReReports(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.DeleteReOrgReReports,
		service   : {
			func   : api.deleteReOrgReReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getActualKindsReports(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.GetActualKindsReOrgReports,
		service   : {
			func   : api.getActualKindsReOrgReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllPaged(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.GetAllPaged,
		service   : {
			func   : api.getAllPaged,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllReports(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.GetAllReports,
		service   : {
			func   : api.getAllReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function updateReOrgReReport(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.UpdateReOrgReReport,
		service   : {
			func   : api.updateReOrgReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function uploadReportLevelsFromFile(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.UploadFromFile,
		service   : {
			func   : api.uploadReportLevelsFromFile,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function uploadReportOrgsFromFile(values){
	const dispatchObj = {
		constants : reOrgReReportConstants.UploadFromFile,
		service   : {
			func   : api.uploadReportOrgsFromFile,
			params : values
		}
	}
	return defAction(dispatchObj)
}