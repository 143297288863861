export const reOrgReportReOrgConstants = {
	CreateReOrgReportReOrgs: {
		FAILURE : "CREATE_REORGREPORTREOORGS_FAILURE",
		REQUEST : "CREATE_REORGREPORTREOORGS_REQUEST",
		SUCCESS : "CREATE_REORGREPORTREOORGS_SUCCESS"
	},
	DeleteReOrgReportReOrg: {
		FAILURE : "DELETE_REORGREPORTREOORG_FAILURE",
		REQUEST : "DELETE_REORGREPORTREOORG_REQUEST",
		SUCCESS : "DELETE_REORGREPORTREOORG_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REORGREPORTREOORG_ALL_PAGED",
		FAILURE : "GET_REORGREPORTREOORG_All_PAGED_FAILURE",
		REQUEST : "GET_REORGREPORTREOORG_All_PAGED_REQUEST",
		SUCCESS : "GET_REORGREPORTREOORG_All_PAGED_SUCCESS"
	},
	GetAllReOrgs: {
		FAILURE : "GET_REORGREPORTREOORG_All_REORGS_FAILURE",
		REQUEST : "GET_REORGREPORTREOORG_All_REORGS_REQUEST",
		SUCCESS : "GET_REORGREPORTREOORG_All_REORGS_SUCCESS"
	},
	IsAnyReOrgs: {
		FAILURE : "GET_REORGREPORTREOORG_IS_ANY_REORGS_FAILURE",
		REQUEST : "GET_REORGREPORTREOORG_IS_ANY_REORGS_REQUEST",
		SUCCESS : "GET_REORGREPORTREOORG_IS_ANY_REORGS_SUCCESS"
	}
}