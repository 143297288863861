import { userHistoryConstants } from "./constants";
import notice from "../../../components/Notice";

const initialState = {
  //get
  getLoading: false,
  userHistoryData: null,
  getError: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    //get
    case userHistoryConstants.Get.REQUEST:
      return {
        ...state,
        getLoading: true,
        userHistoryData: null,
        getError: null,
      };
    case userHistoryConstants.Get.SUCCESS:
      return {
        ...state,
        getLoading: false,
        userHistoryData: action.payload.result,
      };
    case userHistoryConstants.Get.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        getLoading: false,
        userHistoryData: null,
        getError: action.payload.err.data,
      };
    default:
      return state;
  }
}
