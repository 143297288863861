import {
	reInterimReasonsConstants
}from './constants'

const initialState =
{
	changed   : null,
	isSending : false,
	list      : [],
	paged     : {}
}

export default function (state = initialState, action){
	switch (action.type){
		case reInterimReasonsConstants.GetList.REQUEST:
		case reInterimReasonsConstants.GetPaged.REQUEST:
		case reInterimReasonsConstants.Change.REQUEST:
			return {
				...state,
				changed   : null,
				isSending : true
			}
		case reInterimReasonsConstants.GetList.SUCCESS:
			return {
				...state,
				isSending : false,
				list      : action.payload.result
			}
		case reInterimReasonsConstants.GetPaged.SUCCESS:
			return {
				...state,
				isSending : false,
				paged     : action.payload.result
			}
		case reInterimReasonsConstants.Change.SUCCESS:
			return {
				...state,
				changed   : action.payload.result,
				isSending : false
			}
		case reInterimReasonsConstants.GetList.FAILURE:
		case reInterimReasonsConstants.GetPaged.FAILURE:
		case reInterimReasonsConstants.Change.FAILURE:
			return {
				...state,
				isSending: false
			}
		case reInterimReasonsConstants.Clear:
			return {
				...state,
				changed   : null,
				isSending : false,
				list      : [],
				paged     : {}
			}
		default:
			return state
	}
}