import { 
  reAddressTypesConstants 
} from './constants'

const initialState =
{
  isSending: false,
  currentItem: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reAddressTypesConstants.SEND_ADDRESSTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reAddressTypesConstants.SEND_ADDRESSTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reAddressTypesConstants.SEND_ADDRESSTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    default:
      return state
  }
}