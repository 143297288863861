import {
	reGosThemesConstants
}from './constants'

const initialState =
{
	isSending   : false,
	currentItem : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reGosThemesConstants.SEND_REGOSTHEMES_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reGosThemesConstants.SEND_REGOSTHEMES_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reGosThemesConstants.SEND_REGOSTHEMES_FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reGosThemesConstants.CLEAR:{
			return {
				...state,
				currentItem: null
			}
		}
		default:
			return state
	}
}