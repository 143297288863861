import {
	reIndexConstans
}from "./constans"
import notice from "../../../../components/Notice"
import i18n from "../locale"
import isRu from "../../../../helpers/isRu"
import {
	normalize
}from "path"
import {
	appealsConstants
}from "../../../Appeals/store/constants"

const initialState = {
	//deleteIndex
	indexDeleted: false,

	//changeIndex
	indexUpdated: false,

	//restoreIndex
	indexRestored: false,

	//createIndex;
	indexCreated: false,

	//getIndex
	indices   : {},
	indicesList : [],
	isSending : false
}

export default function (state = initialState, action){
	switch (action.type){
		//updateIndex
		case appealsConstants.SET_FILTERS :{
			return {
				...state,
				currentItemOld: state.indices
			}
		}
		case reIndexConstans.UpdateIndex.REQUEST :
			return {
				...state,
				indexUpdated: false
			}
		case reIndexConstans.UpdateIndex.SUCCESS :
			notice("success", i18n.dataUpdated[isRu()])
			return {
				...state,
				indexUpdated: true
			}
		case reIndexConstans.UpdateIndex.FAILURE :
			return {
				...state,
				indexUpdated : false,
				error        : action.payload
			}

			//deleteIndex
		case reIndexConstans.DeleteIndex.REQUEST :
			return {
				...state,
				indexDeleted: false
			}
		case reIndexConstans.DeleteIndex.SUCCESS :
			notice("success", i18n.dataDeleted[isRu()])
			return {
				...state,
				indexDeleted: true
			}
		case reIndexConstans.DeleteIndex.FAILURE :
			return {
				...state,
				indexDeleted : false,
				error        : action.payload
			}

			//restoreIndex
		case reIndexConstans.RestoreIndex.REQUEST :
			return {
				...state,
				indexRestored: false
			}
		case reIndexConstans.RestoreIndex.SUCCESS :
			notice("success", i18n.successRestoreData[isRu()])
			return {
				...state,
				indexRestored: true
			}
		case reIndexConstans.RestoreIndex.FAILURE :
			return {
				...state,
				indexRestored : false,
				error         : action.payload
			}

			//createIndex
		case reIndexConstans.CreateIndex.REQUEST :
			return {
				...state,
				indexCreated: false
			}
		case reIndexConstans.CreateIndex.SUCCESS :
			notice("success", i18n.dataCreated[isRu()])
			return {
				...state,
				indexCreated: true
			}
		case reIndexConstans.CreateIndex.FAILURE :
			return {
				...state,
				indexCreated : false,
				error        : action.payload
			}

			//getAllPaged
		case reIndexConstans.GetAllPaged.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reIndexConstans.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending : false,
				indices   : action.payload.result
			}
		case reIndexConstans.GetAllPaged.FAILURE :
			return {
				...state,
				indexCreated : false,
				error        : action.payload
			}
		case reIndexConstans.GetAllPaged.CLEAR :
			return {
				...state,
				isSending : false,
				indices   : {}
			}

			//getList
		case reIndexConstans.GetList.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reIndexConstans.GetList.SUCCESS :
			return {
				...state,
				isSending : false,
				indicesList   : action.payload.result
			}
		case reIndexConstans.GetList.FAILURE :
			return {
				...state,
				indexCreated : false,
				error        : action.payload
			}
		case reIndexConstans.GetList.CLEAR :
			return {
				...state,
				isSending : false,
				indicesList   : []
			}
		default :
			return state
	}
}
