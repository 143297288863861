import {
	reHierarchyLevelReReportConstants
}from "./constants"
import i18n from "../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	error                : null,
	isSending            : false,
	levelReReportChanged : null,
	levelReReports       : {},
	levelReReportsExpand : {}
}

export default function (state = initialState, action){
	switch (action.type){
		case reHierarchyLevelReReportConstants.CreateLevelReReport.REQUEST :
		case reHierarchyLevelReReportConstants.CreateLevelReReports.REQUEST :
		case reHierarchyLevelReReportConstants.DeleteLevelReReport.REQUEST :
		case reHierarchyLevelReReportConstants.DeleteLevelReReports.REQUEST :
		case reHierarchyLevelReReportConstants.UpdateLevelReReport.REQUEST :
			return {
				...state,
				isSending            : true,
				levelReReportChanged : null
			}
		case reHierarchyLevelReReportConstants.CreateLevelReReport.FAILURE :
		case reHierarchyLevelReReportConstants.CreateLevelReReports.FAILURE :
		case reHierarchyLevelReReportConstants.DeleteLevelReReport.FAILURE :
		case reHierarchyLevelReReportConstants.DeleteLevelReReports.FAILURE :
		case reHierarchyLevelReReportConstants.UpdateLevelReReport.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				levelReReportChanged : null
			}
		case reHierarchyLevelReReportConstants.CreateLevelReReport.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : action.payload.result ? i18n.reportAdd[isRu()] : null
			}
		case reHierarchyLevelReReportConstants.CreateLevelReReports.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : action.payload.result ? i18n.reportsAdd[isRu()] : null
			}
		case reHierarchyLevelReReportConstants.DeleteLevelReReport.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : action.payload.result ? i18n.reportDeleted[isRu()] : null
			}
		case reHierarchyLevelReReportConstants.DeleteLevelReReports.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : action.payload.result ? i18n.reportsDeleted[isRu()] : null
			}
		case reHierarchyLevelReReportConstants.UpdateLevelReReport.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : action.payload.result ? i18n.reportUpdate[isRu()] : null
			}
		case reHierarchyLevelReReportConstants.GetAllPaged.REQUEST :
			return {
				...state,
				isSending            : true,
				levelReReportChanged : null
			}
		case reHierarchyLevelReReportConstants.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending      : false,
				levelReReports : action.payload.result
			}
		case reHierarchyLevelReReportConstants.GetAllPaged.FAILURE :
			return {
				...state,
				error          : action.payload,
				isSending      : false,
				levelReReports : {}
			}
		case reHierarchyLevelReReportConstants.GetAllPagedExpand.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reHierarchyLevelReReportConstants.GetAllPagedExpand.SUCCESS :
			return {
				...state,
				isSending            : false,
				levelReReportsExpand : action.payload.result
			}
		case reHierarchyLevelReReportConstants.GetAllPagedExpand.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				levelReReportsExpand : {}
			}
		case reHierarchyLevelReReportConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isSending            : false,
				levelReReportChanged : null,
				levelReReports       : {},
				levelReReportsExpand : {}
			}
		default :
			return state
	}
}
