import React, {
	useEffect, useState
}from "react"
import {
	Empty,
	Table,
	ConfigProvider,
	Popconfirm,
	Tag,
	Tooltip,
	Row,
	Form,
	Select
}from "antd"
import {
	useDebounce
}from "use-lodash-debounce"
import {
	reOrgReReportActions
}from "./store/actions"
import {
	reHierarchyLevelsActions
}from "../ReHierarchyLevels/store/actions"
import {
	useDispatch, useSelector
}from "react-redux"
import "antd/lib/popconfirm/style/css.js"
import "antd/lib/tag/style/index.css"
import 'antd/lib/tooltip/style/index.css'
import 'antd/lib/empty/style/index.css'
import '../../../pagination.css'
import i18n from "./localization"
import i18nGlobal from "../../../localization"
import ReOrgReReportUpdateForm from "./modify"
import ReOrgReReportCreateForm from "./add"
import './style.css'
import {
	CustomInput
}from "../../../components/CustomInput"
import {
	CustomSelect
}from '../../../components/CustomSelect'
import CustomButton from '../../../components/ButtonCustom'
import Search from '../../../../public/search.svg'
import Plus from '../../../../public/Glyph_ .svg'
import Edit from '../../../../public/Edit.svg'
import Cross from '../../../../public/Cross.svg'
import LocalLoading from "../../../components/Loading/Local"
import Notifications from "../../../helpers/Notifications"
import UploadFromFile from "./addFormFile"

const ReOrgReReports = (props) => {
	const { isModify = false, org, active } = props
	const pageSize = 10
	const [table, setTable] = useState([])
	const [page, setPage] = useState(1)
	const [searchValue, setSearchValue] = useState("")
	const [visibleAddForm, setVisibleAddForm] = useState(false)
	const [visibleUpdateForm, setVisibleUpdateForm] = useState(false)
	const [isLevel, setIsLevel] = useState(false)
	const [record, setRecord] = useState(null)
	const searchWithPause = useDebounce(searchValue, 500)
	const dispatch = useDispatch()
	const isRu = useSelector((state) => state.globalReducer.isRu)
	const levels = useSelector(state => state.reHierarchyLevelReducer.currentItem)
	const levelOrg = useSelector(state => state.reHierarchyLevelReducer.levelOrg)
	const { isSending, reOrgReReportCreated, reOrgReReportsCreated, reOrgReReportDeleted,
		reOrgReReportsDeleted, reOrgReReportUpdated, reOrgReReports } = useSelector((state) => state.reOrgReReportReducer)

	const columns = [
		{
			dataIndex : 'reportKind',
			editable  : false,
			inputType : 'text',
			key       : 'index',
			render    : ((value) => {
				return isRu ? value.name : value.nameBel
			}),
			title : i18n.tableItems[isRu][0],
			width : 250
		},
		{
			dataIndex : 'name',
			editable  : false,
			inputType : 'text',
			key       : 'index',
			render    : ((value, record) => {
				return isRu ? record.name : record.nameBel
			}),
			title : i18n.tableItems[isRu][1],
			width : 500
		},
		{
			dataIndex : 'code',
			editable  : false,
			inputType : 'text',
			key       : 'index',
			title     : i18n.tableItems[isRu][3],
			width     : 100
		},
		{
			dataIndex : 'reportHierarchies',
			editable  : false,
			inputType : 'text',
			key       : 'index',
			render    : ((value) => {
				return (
					<>
						{ value?.map((item) => {
							return (item.reReportHierarchy ?
								(<Tooltip
									title={ (<span style={ { whiteSpace: "pre-wrap" } }>
										{ isRu ? `${ item.reReportHierarchy.name }${ item.isForAllReOrgs ? `\n(${ i18n.isForAllOrgs[isRu] })`: "" }`: `${ item.reReportHierarchy.nameBel }${ item.isForAllReOrgs ? `\n(${ i18n.isForAllOrgs[isRu] })` : "" }` }
									</span>) }
								>
									<Tag>
										{ item.reReportHierarchy.code }
									</Tag>
								</Tooltip>) : null
							)
						}) }
					</>)
			}),
			title : i18n.tableItems[isRu][4],
			width : 150
		}
	]

	Boolean(isModify && !isLevel) && columns.push({
		dataIndex : "change",
		key       : "update",
		render    : (id, record) => {
			return (
				<img
					src={ Edit }
					title={ i18n.update[isRu] }
					onClick={ () => {
						if (record.isLevel)
							Notifications.infoNotice(i18n.infoOnly[isRu], "", 15)
						else
							showUpdateForm(id)
					} }
				/>
			)
		},
		width: 20
	}, {
		dataIndex : "delete",
		key       : "delete",
		render    : (id, record) => (
			<Popconfirm
				cancelText={ i18nGlobal.no[isRu] }
				okText={ i18nGlobal.yes[isRu] }
				title={ i18n.sureToDelete[isRu] }
				onCancel={ () => {
					return null
				} }
				onConfirm={ () => {
					if (record.isLevel)
							Notifications.infoNotice(i18n.infoOnly[isRu], "", 15)
						else
							deleteReOrgReReports({
								reOrgId  : org?.id,
								reportId : id
							})
				} }
			>
				<img
					title={ i18n.delete[isRu] }
					src={ Cross }
				/>
			</Popconfirm>
		),
		width: 20
	})

	const getAllPaged = (params) => {
		dispatch(reOrgReReportActions.getAllPaged(params))
	}
	const updateReOrgReReport = (params) => {
		dispatch(reOrgReReportActions.updateReOrgReReport(params))
	}
	const createReOrgReReports = (params) => {
		dispatch(reOrgReReportActions.createReOrgReReports(params))
	}
	const createReOrgReReport = (params) => {
		dispatch(reOrgReReportActions.createReOrgReReport(params))
	}
	const deleteReOrgReReport = (params) => {
		dispatch(reOrgReReportActions.deleteReOrgReReport(params))
	}
	const deleteReOrgReReports = (params) => {
		dispatch(reOrgReReportActions.deleteReOrgReReports(params))
	}

	const getLevel = (params) => {
		dispatch(reHierarchyLevelsActions.getLevel(params))
	}

	const setLevel = (params) => {
		dispatch(reHierarchyLevelsActions.setLevel(params))
	}

	function showUpdateForm(id){
		const item = reOrgReReports?.items?.find((value) => {
			return value.reReport.id === id
		})
		setRecord(item)
		setVisibleUpdateForm(true)
	}

	useEffect(() => {
		isModify && dispatch(reHierarchyLevelsActions.getLevels({ page: 1, pageSize: 100, orgId: org?.id }))
		return () => {
			setRecord(null)
			setSearchValue("")
			setPage(1)
			setIsLevel(false)
			dispatch(reOrgReReportActions.clear())
			dispatch(reHierarchyLevelsActions.clear())
		}
	}, [])

	useEffect(() => {
		if (reOrgReReports?.items){
			const temp = []
			if (reOrgReReports.items.length){
				reOrgReReports.items.forEach((value, index) => {
					setIsLevel(value.isLevel)
					temp.push({
						change            : isModify && value.reReport.id,
						code              : value.reReport.code,
						delete            : isModify && value.reReport.id,
						isLevel						: value.isLevel,
						name              : value.reReport.name,
						nameBel           : value.reReport.nameBel,
						reportHierarchies : value.reOrgReReportHierarchies,
						reportId          : value.reReport.id,
						reportKind        : value.reReport.reReportKind,
						rowKey            : index
					})
				})
			}
			else if (reOrgReReports.count > 0 && page > 1)
				onPaginationChange(page-1)
			if (record){
				const item = reOrgReReports?.items?.find((value) => {
					return value.reReport.id === record.reReport.id
				})
				setRecord(item)
				if (!item)
					setVisibleUpdateForm(false)
			}
			setTable(temp)
		}
		else
			setTable([])
	}, [reOrgReReports])

	useEffect(() => {
		onPaginationChange(1)
	}, [searchWithPause, org, levelOrg])

	useEffect(()=>{
		if (org)
			getLevel({reOrgId: org.id})
	}, [org, reOrgReReportsCreated])

	useEffect(() => {
		if (reOrgReReportCreated || reOrgReReportsCreated || reOrgReReportDeleted || reOrgReReportsDeleted || reOrgReReportUpdated)
			onPaginationChange(page)
	}, [
		reOrgReReportCreated,
		reOrgReReportsCreated,
		reOrgReReportDeleted,
		reOrgReReportsDeleted,
		reOrgReReportUpdated
	])

	function onPaginationChange(value){
		setPage(value)
		getAllPaged({
			name     : searchWithPause?.trim(),
			page     : value,
			pageSize : pageSize,
			reOrgId  : org?.id
		})
	}

	function onChangeLevel(level){
		setLevel({ reOrgId: org?.id, levelId: level})
	}

	return (
		<>
			{ window.scrollTo(0, 0) }
			<ConfigProvider renderEmpty={ () => <Empty /> }>
				{ isModify && (
					<>
						<ReOrgReReportCreateForm
							create={ createReOrgReReports }
							levels= {levels}
							orgId={ org?.id }
							setVisible={ setVisibleAddForm }
							visible={ visibleAddForm }
						/>
						<ReOrgReReportUpdateForm
							createReOrgReport={ createReOrgReReport }
							deleteReOrgReport={ deleteReOrgReReport }
							orgId={ org?.id }
							record={ record }
							setRecord={ setRecord }
							setVisible={ setVisibleUpdateForm }
							updateReOrgReport={ updateReOrgReReport }
							visible={ visibleUpdateForm }
						/>
						<Row className="rowMargin">
							<div className="addButton">
								<CustomButton
									disabled={ isSending }
									type="orange"
									onClick={ () => setVisibleAddForm(true) }
								>
									<img
										alt={ i18n.add[isRu] }
										src={ Plus }
									/>
									<span>
										{ i18n.add[isRu] }
									</span>
								</CustomButton>
							</div>
							{ active && (
								<div className="uploadReportOrgFile">
									<UploadFromFile isReports />
								</div>
								)
							}
						</Row>
						<Form.Item 
							label={i18n.tableItemsAll[isRu][2]} 
							labelCol={{span: 24}} 										
							tooltip={ { title: i18n.helpHierarchy[isRu] } }
						>
							<CustomSelect
								allowClear
								showSearch
								disabled={isSending}
								filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
								placeholder={ i18n.changeLevelPlaceholder[isRu] }
								style={ { width: "100%" } }
								onChange={ onChangeLevel }
								value={levelOrg}
							>
								{ levels?.items?.map((level, index) => {
										return (
											<Select.Option
												key={ index }
												value={ level.id }
											>
												{ `(${level.code}) ${isRu || !level.nameBel ? level.name : level.nameBel}` }
											</Select.Option>
										)
									})
								}
							</CustomSelect>
						</Form.Item>
					</>
				) }
				<Row>
					<CustomInput
						prefix={<img src={ Search }/>}
						allowClear
						maxLength={ 100 }
						placeholder={ i18n.inputSearch[isRu] }
						onChange={ (e) => setSearchValue(e.target.value) }
					/>
				</Row>
				<Row>
					<Table
						className="reOrgReReports_table"
						loading={ { indicator: <LocalLoading/>, spinning : isSending } }
						columns={ columns }
						dataSource={ table }
						pagination={ {
							current         : page,
							position        : ['bottomCenter'],
							onChange        : onPaginationChange,
							pageSize        : pageSize,
							size						: "small",
							showSizeChanger : false,
							total           : reOrgReReports?.count ?? 0
						} }
						rowKey={ (table) => table.rowKey }
						scroll={ { x: 1000 } }
					/>
				</Row>
			</ConfigProvider>
		</>
	)
}

export default ReOrgReReports
