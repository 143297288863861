import {
  reOperationTypesConstants
} from './constants'
import { i18n } from '../localization'
import isRu from '../../../helpers/isRu'

const initialState =
{
  isSending: false,
  currentItem: {},
  operationTypeChanged: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reOperationTypesConstants.SEND_REOPERATIONTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reOperationTypesConstants.SEND_REOPERATIONTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reOperationTypesConstants.SEND_REOPERATIONTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reOperationTypesConstants.createOperationType.REQUEST:
    case reOperationTypesConstants.deleteOperationType.REQUEST:
    case reOperationTypesConstants.restoreOperationType.REQUEST:
    case reOperationTypesConstants.updateOperationType.REQUEST:
      return {
        ...state,
        isSending: true,
        operationTypeChanged: null,
      }
    case reOperationTypesConstants.createOperationType.FAILURE:
    case reOperationTypesConstants.deleteOperationType.FAILURE:
    case reOperationTypesConstants.restoreOperationType.FAILURE:
    case reOperationTypesConstants.updateOperationType.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      }
    case reOperationTypesConstants.createOperationType.SUCCESS:
      return {
        ...state,
        isSending: false,
        operationTypeChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : '',
      }
    case reOperationTypesConstants.deleteOperationType.SUCCESS:
      return {
        ...state,
        isSending: false,
        operationTypeChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : '',
      }
    case reOperationTypesConstants.restoreOperationType.SUCCESS:
      return {
        ...state,
        isSending: false,
        operationTypeChanged: action.payload ? i18n.messageItemRestoreSuccses[isRu()] : null,
      }
    case reOperationTypesConstants.updateOperationType.SUCCESS:
      return {
        ...state,
        isSending: false,
        operationTypeChanged: action.payload ? i18n.messageItemUpdateSuccses[isRu()] : null,
      }
    case reOperationTypesConstants.SEND_REOPERATIONTYPES_CLEAR:
      return {
        ...state,
        isSending: false,
        operationTypeChanged: null,
        currentItem: {},
      }  
    default:
      return state
  }
}