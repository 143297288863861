export const uploadFileConstants = {

	UploadFiles: {
		REQUEST : "UPLOAD_FILE_REQUEST",
		SUCCESS : "UPLOAD_FILE_SUCCESS",
		FAILURE : "UPLOAD_FILE_FAILURE",
		CLEAR   : "UPLOAD_FILE_CLEAR"
	},

	DeleteFiels: {
		REQUEST : "DELETE_FILE_REQUEST",
		SUCCESS : "DELETE_FILE_SUCCESS",
		FAILURE : "DELETE_FILE_FAILURE"
	},

	DeleteAppealOrgFile: {
		REQUEST : "DELETE_APPEAL_ORG_FILE_REQUEST",
		SUCCESS : "DELETE_APPEAL_ORG_FILE_SUCCESS",
		FAILURE : "DELETE_APPEAL_ORG_FILE_FAILURE"
	},

	DeleteResolutionFile: {
		REQUEST : "DELETE_RESOLUTION_FILE_REQUEST",
		SUCCESS : "DELETE_RESOLUTION_FILE_SUCCESS",
		FAILURE : "DELETE_RESOLUTION_FILE_FAILURE"
	},

	CHOOSE_FILE  : "CHOOSE_FILE",
	REMOVE_FILE  : "REMOVE_FILE",
	CLEAR_FILES  : "CLEAR_FILES",
	CHOOSE_FILES : "CHOOSE_FILES"
}