import { reOrgsConstants } from "./constants";
import { userConstants } from "../../../store/constants/user.constants";
import i18n from "../localization";
import isRu from "../../../helpers/isRu";

const initialState = {
  isSending: false,
  currentItem: {},
  reOrgByUnp: null,
  reOrgByThemes: null,
  reOrgsByParentId: {},
  reOrgById: null,
  minReOrgSending: false,
  minReOrgByNameOrUNP: [],
  isRegistered: null,
  isRegisteredSending: false,
  reOrgTypes: [],
  reOrgStatusTypes: [],
  orgChanged: null,
  isOrgAvaible: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case userConstants.Logout.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: {},
      };
    case reOrgsConstants.SEND_REORGS_REQUEST:
      return {
        ...state,
        isSending: true,
        currentItem: [],
      };
    case reOrgsConstants.SEND_REORGS_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload,
      };
    case reOrgsConstants.SEND_REORGS_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };

    case reOrgsConstants.GetReOrgByThemesOrSubThemes.REQUEST:
      return {
        ...state,
        isSending: true,
        reOrgByThemes: null,
      };
    case reOrgsConstants.GetReOrgByThemesOrSubThemes.SUCCESS:
      return {
        ...state,
        isSending: false,
        reOrgByThemes: action.payload.result,
      };
    case reOrgsConstants.GetReOrgByThemesOrSubThemes.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };

    case reOrgsConstants.GetReOrgByThemesOrSubThemes.REQUEST:
      return {
        ...state,
        isSending: true,
        reOrgByUnp: null,
      };
    case reOrgsConstants.GetReOrgByUNP.SUCCESS:
      return {
        ...state,
        isSending: false,
        reOrgByUnp: action.payload.result,
      };
    case reOrgsConstants.GetReOrgByUNP.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case reOrgsConstants.GetReOrgById.REQUEST:
      return {
        ...state,
        isSending: true,
        reOrgById: null,
      };
    case reOrgsConstants.GetReOrgById.SUCCESS:
      return {
        ...state,
        isSending: false,
        reOrgById: action.payload.result,
      };
    case reOrgsConstants.GetReOrgById.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case reOrgsConstants.GetReOrgById.CLEAR:
      return {
        ...state,
        reOrgById: null,
      };

    case reOrgsConstants.CheckReOrgIsAvailable.REQUEST:
      return {
        ...state,
        isSending: true,
        isOrgAvaible: null,
      };
    case reOrgsConstants.CheckReOrgIsAvailable.SUCCESS:
      return {
        ...state,
        isSending: false,
        isOrgAvaible: action.payload.result,
      };
    case reOrgsConstants.CheckReOrgIsAvailable.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case reOrgsConstants.CheckReOrgIsAvailable.CLEAR:
      return {
        ...state,
        isSending: false,
        isOrgAvaible: null,
      };

    case reOrgsConstants.GetMinReOrgByNameOrUNP.REQUEST:
    case reOrgsConstants.GetReOrgsForApllicantsList.REQUEST:
      return {
        ...state,
        minReOrgSending: true,
        minReOrgByNameOrUNP: null,
      };
    case reOrgsConstants.GetMinReOrgByNameOrUNP.SUCCESS:
    case reOrgsConstants.GetReOrgsForApllicantsList.SUCCESS:
      return {
        ...state,
        minReOrgSending: false,
        minReOrgByNameOrUNP: action.payload.result,
      };
    case reOrgsConstants.GetMinReOrgByNameOrUNP.FAILURE:
    case reOrgsConstants.GetReOrgsForApllicantsList.FAILURE:
      return {
        ...state,
        minReOrgSending: false,
        error: action.payload,
      };
    case reOrgsConstants.GetMinReOrgByNameOrUNP.CLEAR:
    case reOrgsConstants.GetReOrgsForApllicantsList.CLEAR:
      return {
        ...state,
        minReOrgByNameOrUNP: null,
      };

    case reOrgsConstants.CheckReOrgIsRegistered.REQUEST:
      return {
        ...state,
        isRegisteredSending: true,
        isRegistered: null,
      };
    case reOrgsConstants.CheckReOrgIsRegistered.SUCCESS:
      return {
        ...state,
        isRegisteredSending: false,
        isRegistered: action.payload.result,
      };
    case reOrgsConstants.CheckReOrgIsRegistered.FAILURE:
      return {
        ...state,
        isRegisteredSending: false,
        error: action.payload,
        isRegistered: action.payload,
      };
    case reOrgsConstants.CheckReOrgIsRegistered.CLEAR:
      return {
        ...state,
        isRegistered: null,
      };

    case reOrgsConstants.GetTypesStatuses.CLEAR:
      return {
        ...state,
        currentItem: {},
        isSending: false,
        orgChanged: null,
        reOrgStatusTypes: [],
        reOrgTypes: [],
      };
    case reOrgsConstants.GetTypesStatuses.REQUEST:
      return {
        ...state,
        reOrgStatusTypes: [],
        reOrgTypes: [],
      };
    case reOrgsConstants.GetTypesStatuses.SUCCESS:
      return {
        ...state,
        reOrgStatusTypes: action.payload.result.reOrgStatusTypes,
        reOrgTypes: action.payload.result.reOrgTypes,
      };
    case reOrgsConstants.GetTypesStatuses.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case reOrgsConstants.Create.REQUEST:
    case reOrgsConstants.Delete.REQUEST:
    case reOrgsConstants.Update.REQUEST:
      return {
        ...state,
        isSending: true,
        orgChanged: null,
      };
    case reOrgsConstants.Create.SUCCESS:
      return {
        ...state,
        isSending: false,
        orgChanged: action.payload.result
          ? i18n.messageItemAddSuccses[isRu()]
          : null,
      };
    case reOrgsConstants.Delete.SUCCESS:
      return {
        ...state,
        isSending: false,
        orgChanged: action.payload.result
          ? i18n.messageItemDeleteSuccses[isRu()]
          : null,
      };
    case reOrgsConstants.Update.SUCCESS:
      return {
        ...state,
        isSending: false,
        orgChanged: action.payload.result
          ? i18n.messageItemUpdateSuccses[isRu()]
          : null,
      };
    case reOrgsConstants.Create.FAILURE:
    case reOrgsConstants.Delete.FAILURE:
    case reOrgsConstants.Update.FAILURE:
      return {
        ...state,
        error: action.payload,
        isSending: false,
        orgChanged: null,
      };

    default:
      return state;
  }
}
