import {
	reAppealViewTypesConstants
}from './constants'

import i18n from '../localization'
import isRu from "../../../helpers/isRu"

const initialState =
{
	isSending              : false,
	currentItem            : {},
	error                  : null,
	appealViewTypesChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reAppealViewTypesConstants.SEND_REAPPEALVIEWTYPES_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reAppealViewTypesConstants.SEND_REAPPEALVIEWTYPES_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reAppealViewTypesConstants.SEND_REAPPEALVIEWTYPES_FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reAppealViewTypesConstants.CreateAppealViewTypes.REQUEST:
		case reAppealViewTypesConstants.DeleteAppealViewTypes.REQUEST:
		case reAppealViewTypesConstants.RestoreAppealViewTypes.REQUEST:
		case reAppealViewTypesConstants.UpdateAppealViewTypes.REQUEST:
			return {
				...state,
				isSending              : true,
				appealViewTypesChanged : null
			}
		case reAppealViewTypesConstants.CreateAppealViewTypes.FAILURE:
		case reAppealViewTypesConstants.DeleteAppealViewTypes.FAILURE:
		case reAppealViewTypesConstants.RestoreAppealViewTypes.FAILURE:
		case reAppealViewTypesConstants.UpdateAppealViewTypes.FAILURE:
			return {
				...state,
				isSending              : false,
				error                  : action.payload,
				appealViewTypesChanged : null
			}
		case reAppealViewTypesConstants.CreateAppealViewTypes.SUCCESS:
			return {
				...state,
				isSending              : false,
				appealViewTypesChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reAppealViewTypesConstants.DeleteAppealViewTypes.SUCCESS:
			return {
				...state,
				isSending              : false,
				appealViewTypesChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reAppealViewTypesConstants.UpdateAppealViewTypes.SUCCESS:
			return {
				...state,
				isSending              : false,
				appealViewTypesChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reAppealViewTypesConstants.RestoreAppealViewTypes.SUCCESS:
			return {
				...state,
				isSending              : false,
				appealViewTypesChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}

		default:
			return state
	}
}