export const ReMessageTemplateTypeConstants = {
  CLEAR_MODIFY_RESULT: "CLEAR_MODIFY_RESULT",

  GetMessageTemplatesTypesPaged: {
    REQUEST: "GET_RE_MESSAGE_TEMPLATE_TYPE_PAGED_REQUEST",
    SUCCESS: "GET_RE_MESSAGE_TEMPLATE_TYPE_PAGED_SUCCESS",
    FAILURE: "GET_RE_MESSAGE_TEMPLATE_TYPE_PAGED_FAILURE",
  },

  CreateReMessageTemplateType: {
    REQUEST: "CREATE_RE_MESSAGE_TEMPLATE_TYPE_REQUEST",
    SUCCESS: "CREATE_RE_MESSAGE_TEMPLATE_TYPE_SUCCESS",
    FAILURE: "CREATE_RE_MESSAGE_TEMPLATE_TYPE_FAILURE",
  },

  UpdateReMessageTemplateType: {
    REQUEST: "UPDATE_RE_MESSAGE_TEMPLATE_TYPE_REQUEST",
    SUCCESS: "UPDATE_RE_MESSAGE_TEMPLATE_TYPE_SUCCESS",
    FAILURE: "UPDATE_RE_MESSAGE_TEMPLATE_TYPE_FAILURE",
  },

  DeleteReMessageTemplateType: {
    REQUEST: "DELETE_RE_MESSAGE_TEMPLATE_TYPE_REQUEST",
    SUCCESS: "DELETE_RE_MESSAGE_TEMPLATE_TYPE_SUCCESS",
    FAILURE: "DELETE_RE_MESSAGE_TEMPLATE_TYPE_FAILURE",
  },

  RestoreReMessageTemplateType: {
    REQUEST: "RESTORE_RE_MESSAGE_TEMPLATE_TYPE_REQUEST",
    SUCCESS: "RESTORE_RE_MESSAGE_TEMPLATE_TYPE_SUCCESS",
    FAILURE: "RESTORE_RE_MESSAGE_TEMPLATE_TYPE_FAILURE",
  }
};
