const i18n = {
	add: {
		0 : "Дадаць",
		1 : "Добавить"
	},
	createFormReportsLabel: {
		0 : "Справаздачы",
		1 : "Отчёты"
	},
	createFormReportsPlaceholder: {
		0 : 'Пачніце уводзіць назву ці код справаздач. Пошук пачынаецца з трох знакаў',
		1 : 'Начните вводить название или код отчётов. Поиск начинается с трех символов'
	},
	createFormReportsRule: {
		0 : "Патрабуецца выбраць справаздачу(ы)!",
		1 : "Требуется выбрать отчёт(ы)!"
	},
	dataSearch: {
		0 : "Калі ласка, пачакайце, ідзе атрыманне дадзеных...",
		1 : "Пожалуйста, подождите, идёт получение данных..."
	},
	delete: {
		0 : "Выдаліць",
		1 : "Удалить"
	},
	headerCreateForm: {
		0 : "Дадаць справаздачы",
		1 : "Добавить отчёты"
	},
	headerUpdateForm: {
		0 : "Рэдагаваць справаздачу",
		1 : "Редактировать отчёт"
	},
	inputSearch: {
		0 : 'Пачніце ўводзіць адзін з параметраў справаздачы: Код | Назва',
		1 : 'Начните вводить один из параметров отчёта: Код | Название'
	},
	isForAllOrgs: {
		0 : 'Выбар любой арганізацыі',
		1 : 'Выбор любой организации'
	},
	reportAdd: {
		0 : "Характарыстыка справаздачы паспяхова дададзеная!",
		1 : "Характеристика отчёта успешно добавлена!"
	},
	reportDeleted: {
		0 : "Характарыстыка справаздачы паспяхова выдаленая!",
		1 : "Характеристика отчёта успешно удалена!"
	},
	reportUpdate: {
		0 : "Характарыстыка справаздачы паспяхова абноўлена!!",
		1 : "Характеристика отчёта успешно обновлена!"
	},
	reportsAdd: {
		0 : "Справаздачы паспяхова дададзеныя!",
		1 : "Отчёты успешно добавлены!"
	},
	reportsDeleted: {
		0 : "Справаздача паспяхова выдалена!",
		1 : "Отчёт успешно удалён!"
	},
	reportsEmpty: {
		0 : "Справаздачы не знойдзеныя",
		1 : "Отчёты не найдены"
	},
	selectLevelPlaceholder: {
		0 : "Выберыце ўзровень іерархіі",
		1 : "Выберите уровень иерархии"
	},
	sureToDelete: {
		0 : "Вы ўпэўненыя, што хочаце выдаліць справаздачу?",
		1 : "Вы уверены, что хотите удалить отчёт?"
	},
	tableItems: {
		0: [
			'Выгляд',
			'Назва',
			'Назва па-беларуску',
			'Код',
			'Характарыстыкі'
		],
		1: [
			'Вид',
			'Название',
			'Название на белорусском',
			'Код',
			'Характеристики'
		]
	},
	update: {
		0 : "Рэдагаваць",
		1 : "Редактировать"
	}
}
export default i18n