import {
	userConstants
}from "../../../store/constants/user.constants"
import {
	tasksConstants
}from './constants'

const initialState =
{
	error                : null,
	getAllStatusesResult : {},
	getAllTypesResult    : {},
	isSending            : false,
	getAllControlsResult : {},
	getTasksResult       : {},
	deleteSending        : false,
	deleteResult         : null
}

export default function (state = initialState, action){
	switch (action.type){
		case tasksConstants.GetOwnTasks.REQUEST:
			return {
				...state,
				isSending: true
			}
		case tasksConstants.GetOwnTasks.SUCCESS:
			return {
				...state,
				isSending      : false,
				getTasksResult : action.payload
			}
		case tasksConstants.GetOwnTasks.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case tasksConstants.GetOwnOrgTasks.REQUEST:
			return {
				...state,
				isSending      : true,
				getTasksResult : {}
			}
		case tasksConstants.GetOwnOrgTasks.SUCCESS:
			return {
				...state,
				isSending      : false,
				getTasksResult : action.payload
			}
		case tasksConstants.GetOwnOrgTasks.FAILURE:
			return {
				...state,
				isSending      : false,
				error          : action.payload,
				getTasksResult : {}
			}
		case tasksConstants.GetSubOrgTasks.REQUEST:
			return {
				...state,
				isSending      : true,
				getTasksResult : {}
			}
		case tasksConstants.GetSubOrgTasks.SUCCESS:
			return {
				...state,
				isSending      : false,
				getTasksResult : action.payload
			}
		case tasksConstants.GetSubOrgTasks.FAILURE:
			return {
				...state,
				isSending      : false,
				error          : action.payload,
				getTasksResult : {}
			}
		case tasksConstants.GetAllStatuses.REQUEST:
			return {
				...state,
				isSending            : true,
				getAllStatusesResult : {}
			}
		case tasksConstants.GetAllStatuses.SUCCESS:
			return {
				...state,
				isSending            : false,
				getAllStatusesResult : action.payload
			}
		case tasksConstants.GetAllStatuses.FAILURE:
			return {
				...state,
				isSending            : false,
				error                : action.payload,
				getAllStatusesResult : {}
			}
		case tasksConstants.GetAllTypes.REQUEST:
			return {
				...state,
				isSending         : true,
				getAllTypesResult : {}
			}
		case tasksConstants.GetAllTypes.SUCCESS:
			return {
				...state,
				isSending         : false,
				getAllTypesResult : action.payload
			}
		case tasksConstants.GetAllTypes.FAILURE:
			return {
				...state,
				isSending         : false,
				error             : action.payload,
				getAllTypesResult : {}
			}
		case tasksConstants.GetAllControls.REQUEST:
			return {
				...state,
				isSending            : true,
				getAllControlsResult : {}
			}
		case tasksConstants.GetAllControls.SUCCESS:
			return {
				...state,
				isSending            : false,
				getAllControlsResult : action.payload
			}
		case tasksConstants.GetAllControls.FAILURE:
			return {
				...state,
				isSending            : false,
				error                : action.payload,
				getAllControlsResult : {}
			}
		case tasksConstants.DeleteTask.REQUEST:
			return {
				...state,
				deleteSending : true,
				deleteResult  : null
			}
		case tasksConstants.DeleteTask.SUCCESS:

			return {
				...state,
				deleteSending : false,
				deleteResult  : action.payload.result
			}
		case tasksConstants.DeleteTask.FAILURE:
			return {
				...state,
				deleteSending : false,
				error         : action.payload,
				deleteResult  : null
			}
		case tasksConstants.DeleteTask.CLEAR:
			return {
				...state,

				//deleteSending : false,
				deleteResult: null
			}
		default:
			return state
	}
}