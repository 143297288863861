import React, {
	useEffect, useState
}from "react"
import {
	Modal, Empty, Select, Spin, Tree, Row
}from "antd"
import {
	reReportActions
}from "../ReReports/store/actions"
import {
	reHierarchyLevelReReportActions
}from "../ReHierarchyLevelReReports/store/actions"
import {
	useDebounce
}from "use-lodash-debounce"
import i18nGlobal from "../../../localization"
import i18n from "./localization"
import {
	useDispatch, useSelector
}from "react-redux"
import "antd/lib/empty/style/index.css"
import "antd/lib/tree/style/index.css"
import "antd/lib/spin/style/index.css"
import {
	CustomInput
}from "../../../components/CustomInput"
import {
	Scrollbars
}from "react-custom-scrollbars"
import {
	CustomSelect
}from '../../../components/CustomSelect'
import CustomButton from '../../../components/ButtonCustom'
import LocalLoading from "../../../components/Loading/Local"
import Notifications from "../../../helpers/Notifications"
import Search from '../../../../public/search.svg'

const ReOrgReReportCreateForm = (props) => {
	const dispatch = useDispatch()
	const isRu = useSelector(state => state.globalReducer.isRu)
	const { visible, setVisible, orgId, create, levels } = props
	const [reportName, setReportName] = useState("")
	const searchReportName = useDebounce(reportName, 500)
	const [reportData, setReportData] = useState([])
	const [checkedKeys, setCheckedKeys] = useState([])
	const [allKeys, setAllKeys] = useState([])
	const [first, setFirst] = useState(false)
	const { reports } = useSelector((state) => state.reReportReducer)
	const searchReports = useSelector((state) => state.reReportReducer.isSending)
	const { isSending, reOrgReReportsCreated } = useSelector((state) => state.reOrgReReportReducer)
	const { levelReReports } = useSelector((state) => state.reHierarchyLevelReReportReducer)
	const searchLevelReports = useSelector((state) => state.reHierarchyLevelReReportReducer.isSending)
	const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

	const getAllReports = (params) => {
		dispatch(reReportActions.getAllReportsGroup(params))
	}

	const getLevelReports = (params) => {
		dispatch(reHierarchyLevelReReportActions.getAllPaged(params))
	}

	useEffect(()=>{
		const data = levelReReports?.items
		if (data)
		{
			let add = []
			data.forEach((item) => {
				item.reOrgReReportHierarchies.forEach((hierarchy) => {
					const key = `data_${item.reReport.id}_${hierarchy.reReportHierarchy.id}`;
					if (!checkedKeys.includes(key))
						add.push(key)
				})
			})
			if (add.length)
				setCheckedKeys(add.concat(checkedKeys))
		}
	}, [levelReReports])

	useEffect(() => {
		if (visible){
			getAllReports({
				name: searchReportName,
				isActual: true,
				reOrgId: !first ? orgId : null
			})
		}
	}, [visible, searchReportName])

	useEffect(() => {
		if (reports?.length){
			const keys = []
			const defaultChecked = []
			const expand = []
			const data = { title: i18n.createFormReportsLabel[isRu], key: "all", children: reports.map((data) => {
					return {
						children: data.reReports.map((report) => {
							expand.push(`report${ report.id }`)
							return {
								children: report.reReportHierarchies.map((hierarchy) => {
									const key = `data_${ report.id }_${ hierarchy.id }`
									keys.push(key)
									if (!first && hierarchy.checked)
										defaultChecked.push(key)
									return {
										key   : key,
										title : `${ hierarchy.code } (${ isRu || !hierarchy.nameBel ? hierarchy.name : hierarchy.nameBel })`
									}
								}),
								key   : `report${ report.id }`,
								title : `${ isRu || !report.nameBel ? report.name : report.nameBel } (${ report.code })`
							}
						}),
						key   : `kind${ data.reReportKind.id }`,
						title : isRu || !data.reReportKind.nameBel ? data.reReportKind.name : data.reReportKind.nameBel
					}
				})
			}
			if (defaultChecked.length)
				setCheckedKeys(defaultChecked)
			setFirst(true)
			setAllKeys(keys)
			setReportData([data])
			setAutoExpandParent(true)
			setExpandedKeys(expand)
		}
		else {
			setAllKeys([])
			setReportData([])
			setAutoExpandParent(false)
			setExpandedKeys([])
		}
	}, [reports, isRu])

	useEffect(() => {
		if (reOrgReReportsCreated)
			handleCancel()
	}, [reOrgReReportsCreated])

	function handleCancel(){
		setVisible(false)
		setFirst(false)
		setAutoExpandParent(true)
		dispatch(reHierarchyLevelReReportActions.clear())
		dispatch(reReportActions.clear())
		setReportName("")
		setReportData([])
		setCheckedKeys([])
		setAllKeys([])
	}

	function saveReports(){
		const reportHierarchies = []
		checkedKeys.forEach((key) => {
			const found = key.match(/^data_(\d+)_(\d+)$/)
			if (found?.length){
				reportHierarchies.push({ hierarchy : found[2],
					report    : found[1] })
			}
		})
		create({ reOrgId           : orgId,
			reportHierarchies : reportHierarchies })
	}

	const onCheck = (checked) => {
		const nowCheckedKeys = checked.filter(key => key.indexOf("data") === 0)
		const prevCheckedKeys = checkedKeys.filter(key => !allKeys.includes(key))
		const result = prevCheckedKeys.concat(nowCheckedKeys)
		setCheckedKeys(result)
	}

	const onChangeLevel = (level) => {
		if (level){
			getLevelReports({page     : 1,
				pageSize : 1000,
				isActual: true,
				levelId  : level})
		}
	}

	const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

	return (
		<Modal
			destroyOnClose
			footer={ null }
			title={ i18n.headerCreateForm[isRu] }
			visible={ visible }
			width={ 1000 }
			onCancel={ handleCancel }
		>
			<Row className="rowMargin">
				<CustomSelect
					allowClear
					showSearch
					filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
					placeholder={ i18n.selectLevelPlaceholder[isRu] }
					style={ { width: "100%" } }
					onChange={ onChangeLevel }
					title={ i18n.selectLevelPlaceholder[isRu] }
				>
					{ levels?.items?.map((level, index) => {
							return (
								<Select.Option
									key={ index }
									value={ level.id }
								>
									{ `(${level.code}) ${isRu || !level.nameBel ? level.name : level.nameBel}` }
								</Select.Option>
							)
						})
					}
				</CustomSelect>
			</Row>
			<Row className="rowMargin">
				<CustomInput
					allowClear
					maxLength={ 100 }
					placeholder={ i18n.inputSearch[isRu] }
					prefix={ <img src={ Search } /> }
					onChange={ (e) => setReportName(e.target.value) }
				/>
			</Row>
			<Scrollbars
				autoHeight
				autoHide
				autoHeightMax={ 650 }
			>
				{ (searchReports || searchLevelReports) ? (
					<Spin
						className="spinTreeRR"
						indicator={ <LocalLoading /> }
						tip={ i18n.dataSearch[isRu] }
					/>
				) : !reports?.length ? (
					<Empty description={ i18n.reportsEmpty[isRu] } />
				) : (
					<Tree
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						checkable
						showLine
						checkedKeys={ checkedKeys }
						treeData={ reportData }
						onCheck={ onCheck }
					/>
				) }
			</Scrollbars>
			<Row
				className="rowMargin"
				justify="end"
			>
				<CustomButton
					loading={ isSending }
					type="orange"
					onClick={ () => saveReports() }
				>
					{ i18nGlobal.save[isRu] }
				</CustomButton>
			</Row>
		</Modal>
	)
}
export default ReOrgReReportCreateForm
