import React, {
	useEffect, useState
}from "react"
import {
	Input, Modal, Form, Select, List, Row, Col, Checkbox
}from "antd"
import {
	Scrollbars
}from "react-custom-scrollbars"
import i18n from "./localization"
import {
	useSelector
}from "react-redux"
import 'antd/lib/list/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/card/style/index.css'
import CustomButton from '../../../components/ButtonCustom'
import "./style.css"

const ReOrgReReportUpdateForm = (props) => {
	const isRu = useSelector(state => state.globalReducer.isRu)
	const { visible, setVisible, setRecord, record, orgId, createReOrgReport, updateReOrgReport, deleteReOrgReport } = props
	const { isSending } = useSelector((state) => state.reOrgReReportReducer)
	const [form] = Form.useForm()
	const [values, setValues] = useState({})

	useEffect(() => {
		if (record){
			setValues({
				reOrgReReportHierarchies : record.reOrgReReportHierarchies,
				report                   : record.reReport
			})
		}
	}, [record])

	function handleCancel(){
		form.resetFields()
		setVisible(false)
		setValues({})
		setRecord(null)
	}

	function renderListItem(item){
		const found = values.reOrgReReportHierarchies.find((rhr => (rhr.reReportHierarchy ? rhr.reReportHierarchy.id == item.id : rhr.id == item.id)))
		function IsForAllReOrgs(report){
			return (
				<Checkbox
					checked={ report ? report.isForAllReOrgs : true }
					disabled={ isSending }
					onChange={ (e) => {
						updateReOrgReport({
							id             : report.id,
							isForAllReOrgs : e.target.checked
						})
					} }
				>
					{ i18n.isForAllOrgs[isRu] }
				</Checkbox>
			)
		}
		return (
			<List.Item
				actions={ [
					<CustomButton
						key="update"
						disabled={ isSending }
						type={ !found ? "orange": "grey" }
						onClick={ () => {
							if (!found){
								createReOrgReport({
									isForAllReOrgs      : false,
									reOrgId             : orgId,
									reReportHierarchyId : item.id,
									reReportId          : values.report.id
							 })
							}
							else {
								deleteReOrgReport({
									id: found.id
								})
							}
						} }
					>
						{ !found ? i18n.add[isRu] : i18n.delete[isRu] }
					</CustomButton>
				] }
			>
				<div
					className={ !found ? "notActiveHierarchy" : "" }
					style={ { width: "100%" } }
				>
					{ item.name ?
						(
							<Row justify="center">
								<Col span={ item.isNeedReOrg ? 10 : 24 }>
									<span>
										{ item.name }
									</span>
								</Col>
								{ item.isNeedReOrg &&
								<Col span={ 14 }>
									{ IsForAllReOrgs(found) }
								</Col> }
							</Row>
			 			) :
						(
							IsForAllReOrgs(found)
						) }
				</div>
			</List.Item>
		)
	}

	return (
		<div>
			<Modal
				destroyOnClose
				footer={ null }
				title={ i18n.headerUpdateForm[isRu] }
				visible={ visible }
				width={ 800 }
				onCancel={ handleCancel }
			>
				<Scrollbars
					autoHeight
					autoHide
					autoHeightMax={ 650 }
				>
					<Form
						form={ form }
						id="mainChangeReOrgReReportForm"
						layout="vertical"
						style={ { paddingRight: "10px" } }
					>
						<Form.Item
							label={ isRu ? i18n.tableItems[isRu][1] : i18n.tableItems[isRu][2] }
							name="reportName"
						>
							<>
								<Input.TextArea
									readOnly
									autoSize={ {
										maxRows : 4,
										minRows : 1
									} }
									value={ isRu ? values.report?.name : values.report?.nameBel }
								/>
							</>
						</Form.Item>
						<Form.Item
							label={ i18n.tableItems[isRu][3] }
							name="reportCode"
						>
							<>
								<Input
									readOnly
									value={ values.report?.code }
								/>
							</>
						</Form.Item>
						<Form.Item
							label={ i18n.tableItems[isRu][4] }
							name="reReportHierarchies"
						>
							<>
								<Select
									mode="multiple"
									notFoundContent={ null }
									showArrow={ false }
									value={
									values.report?.reReportHierarchies?.map((item) => {
										return item.id
									})
									}
								>
									{ values.report?.reReportHierarchies?.map((value) => {
										return (
											<Select.Option
												key={ value.id }
												style={ {
													width: "100%"
												} }
												title={
													isRu || !value.nameBel ? value.name : value.nameBel
												}
												value={ value.id }
											>
												{ value.code }
											</Select.Option>
										)
									}) }
								</Select>
							</>
						</Form.Item>
						<List
							dataSource={ values.report?.reReportHierarchies?.length ? values.report?.reReportHierarchies : values.reOrgReReportHierarchies }
							itemLayout="horizontal"
							renderItem={ renderListItem }
							size="small"
						/>
					</Form>
				</Scrollbars>
			</Modal>
		</div>
	)
}
export default ReOrgReReportUpdateForm
