import {
	reOrgStatusTypesConstants
}from "./constants"

const initialState = {
	isSending              : false,
	paged                  : {},
	reOrgStatusTypeChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reOrgStatusTypesConstants.getAllPagedReOrgStatusTypes.REQUEST:
		case reOrgStatusTypesConstants.createReOrgStatusType.REQUEST:
		case reOrgStatusTypesConstants.deleteReOrgStatusType.REQUEST:
		case reOrgStatusTypesConstants.restoreReOrgStatusType.REQUEST:
		case reOrgStatusTypesConstants.updateReOrgStatusType.REQUEST:
			return {
				...state,
				isSending              : true,
				reOrgStatusTypeChanged : null
			}
		case reOrgStatusTypesConstants.getAllPagedReOrgStatusTypes.FAILURE:
		case reOrgStatusTypesConstants.createReOrgStatusType.FAILURE:
		case reOrgStatusTypesConstants.deleteReOrgStatusType.FAILURE:
		case reOrgStatusTypesConstants.restoreReOrgStatusType.FAILURE:
		case reOrgStatusTypesConstants.updateReOrgStatusType.FAILURE:
			return {
				...state,
				isSending: false
			}
		case reOrgStatusTypesConstants.getAllPagedReOrgStatusTypes.SUCCESS:
			return {
				...state,
				isSending : false,
				paged     : action.payload.result
			}
		case reOrgStatusTypesConstants.createReOrgStatusType.SUCCESS:
		case reOrgStatusTypesConstants.deleteReOrgStatusType.SUCCESS:
		case reOrgStatusTypesConstants.restoreReOrgStatusType.SUCCESS:
		case reOrgStatusTypesConstants.updateReOrgStatusType.SUCCESS:
			return {
				...state,
				isSending              : false,
				reOrgStatusTypeChanged : action.payload.result
			}
		case reOrgStatusTypesConstants.clear:
			return {
				...state,
				...initialState
			}
		default:
			return state
	}
}
