export const reOperationTypesConstants = {
  SEND_REOPERATIONTYPES_REQUEST: 'SEND_REOPERATIONTYPES_REQUEST',
  SEND_REOPERATIONTYPES_SUCCESS: 'SEND_REOPERATIONTYPES_SUCCESS',
  SEND_REOPERATIONTYPES_FAILURE: 'SEND_REOPERATIONTYPES_FAILURE',
  SEND_REOPERATIONTYPES_CLEAR: 'SEND_REOPERATIONTYPES_CLEAR',
  createOperationType : {
    REQUEST: 'CREATE_OPERATION_TYPE_REQUEST',
    FAILURE: 'CREATE_OPERATION_TYPE_FAILURE',
    SUCCESS: 'CREATE_OPERATION_TYPE_SUCCESS',
  },
  deleteOperationType : {
    REQUEST: 'DELETE_OPERATION_TYPE_REQUEST',
    FAILURE: 'DELETE_OPERATION_TYPE_FAILURE',
    SUCCESS: 'DELETE_OPERATION_TYPE_SUCCESS',
  },
  restoreOperationType : {
    REQUEST: 'RESTORE_OPERATION_TYPE_REQUEST',
    FAILURE: 'RESTORE_OPERATION_TYPE_FAILURE',
    SUCCESS: 'RESTORE_OPERATION_TYPE_SUCCESS',
  },
  updateOperationType : {
    REQUEST: 'UPDATE_OPERATION_TYPE_REQUEST',
    FAILURE: 'UPDATE_OPERATION_TYPE_FAILURE',
    SUCCESS: 'UPDATE_OPERATION_TYPE_SUCCESS',
  },
  
}