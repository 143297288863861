import { i18n } from '../localization'
import {
  reDocTypesConstants
} from './constants'
import isRu from '../../../helpers/isRu'


const initialState =
{
  isSending: false,
  currentItem: {},
  error: null,
  docTypeChanged: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reDocTypesConstants.SEND_REDOCTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reDocTypesConstants.SEND_REDOCTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reDocTypesConstants.SEND_REDOCTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reDocTypesConstants.createDocType.REQUEST:
    case reDocTypesConstants.deleteDocType.REQUEST:
    case reDocTypesConstants.updateDocType.REQUEST:
    case reDocTypesConstants.restoreDocType.REQUEST:
      return {
        ...state,
        isSending: true,
        docTypeChanged: null
      }
    case reDocTypesConstants.createDocType.FAILURE:
    case reDocTypesConstants.deleteDocType.FAILURE:
    case reDocTypesConstants.deleteDocType.FAILURE:
    case reDocTypesConstants.deleteDocType.FAILURE:
      return {
        ...state,
        isSending: false,
        docTypeChanged: null,
        error: action.payload
      }
    case reDocTypesConstants.createDocType.SUCCESS:
      return {
        ...state,
        isSending: false,
        docTypeChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
      }
    case reDocTypesConstants.deleteDocType.SUCCESS:
      return {
        ...state,
        isSending: false,
        docTypeChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
      }
    case reDocTypesConstants.updateDocType.SUCCESS:
      return {
        ...state,
        isSending: false,
        docTypeChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
      }
    case reDocTypesConstants.restoreDocType.SUCCESS:
      return {
        ...state,
        isSending: false,
        docTypeChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
      }
    case reDocTypesConstants.getReDocType.CLEAR:
      return {
        ...state,
        isSending: false,
        docTypeChanged: null,
        currentItem: {}
      }  
    default:
      return state
  }
}