export const reDocTypesConstants = {
  SEND_REDOCTYPES_REQUEST: 'SEND_REDOCTYPES_REQUEST',
  SEND_REDOCTYPES_SUCCESS: 'SEND_REDOCTYPES_SUCCESS',
  SEND_REDOCTYPES_FAILURE: 'SEND_REDOCTYPES_FAILURE',
  getReDocType : {
    CLEAR: 'GET_DOC_TYPE_CLEAR',
  },
  createDocType : {
    REQUEST: 'CREATE_DOC_TYPE_REQUEST',
    SUCCESS: 'CREATE_DOC_TYPE_SUCCESS',
    FAILURE: 'CREATE_DOC_TYPE_FAILURE',
  },
  deleteDocType: {
    REQUEST: 'DELETE_DOC_TYPE_REQUEST',
    SUCCESS: 'DELETE_DOC_TYPE_SUCCESS',
    FAILURE: 'DELETE_DOC_TYPE_FAILURE',
  },
  updateDocType : {
    REQUEST: 'UPDATE_DOC_TYPE_REQUEST',
    SUCCESS: 'UPDATE_DOC_TYPE_SUCCESS',
    FAILURE: 'UPDATE_DOC_TYPE_FAILURE',
  },
  restoreDocType : {
    REQUEST: 'RESTORE_DOC_TYPE_REQUEST',
    SUCCESS: 'RESTORE_DOC_TYPE_SUCCESS',
    FAILURE: 'RESTORE_DOC_TYPE_FAILURE',
  }
}