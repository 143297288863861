import {
	reHierarchyLevelsConstants
}from "./constants"
import {
	i18n
}from "../../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	currentItem  : {},
	error        : null,
	isSending    : false,
	levelChanged : null,
	levelOrg     : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reHierarchyLevelsConstants.GetLevels.REQUEST:
			return {
				...state,
				currentItem : {},
				error       : null,
				isSending   : true
			}
		case reHierarchyLevelsConstants.GetLevels.SUCCESS:
			return {
				...state,
				currentItem : action.payload.result,
				isSending   : false
			}
		case reHierarchyLevelsConstants.GetLevels.FAILURE:
			return {
				...state,
				currentItem : {},
				error       : action.payload,
				isSending   : false
			}
		case reHierarchyLevelsConstants.CreateLevel.REQUEST:
		case reHierarchyLevelsConstants.DeleteLevel.REQUEST:
		case reHierarchyLevelsConstants.UpdateLevel.REQUEST:
			return {
				...state,
				isSending    : true,
				levelChanged : null
			}
		case reHierarchyLevelsConstants.CreateLevel.FAILURE:
		case reHierarchyLevelsConstants.DeleteLevel.FAILURE:
		case reHierarchyLevelsConstants.UpdateLevel.FAILURE:
			return {
				...state,
				error        : action.payload,
				isSending    : false,
				levelChanged : null
			}
		case reHierarchyLevelsConstants.CreateLevel.SUCCESS:
			return {
				...state,
				isSending    : false,
				levelChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reHierarchyLevelsConstants.DeleteLevel.SUCCESS:
			return {
				...state,
				isSending    : false,
				levelChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reHierarchyLevelsConstants.UpdateLevel.SUCCESS:
			return {
				...state,
				isSending    : false,
				levelChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reHierarchyLevelsConstants.GetLevel.REQUEST:
		case reHierarchyLevelsConstants.SetLevel.REQUEST:
			return {
				...state,
				isSending: true
			}
		case reHierarchyLevelsConstants.GetLevel.SUCCESS:
		case reHierarchyLevelsConstants.SetLevel.SUCCESS:
			return {
				...state,
				isSending : false,
				levelOrg  : action.payload.result ? action.payload.result : null
			}
		case reHierarchyLevelsConstants.GetLevel.FAILURE:
		case reHierarchyLevelsConstants.SetLevel.FAILURE:
			return {
				...state,
				error     : action.payload,
				isSending : false
			}

		case reHierarchyLevelsConstants.GetLevels.CLEAR:
			return {
				...state,
				currentItem  : {},
				error        : null,
				isSending    : false,
				levelChanged : null,
				levelOrg     : null
			}
		default:
			return state
	}
}
