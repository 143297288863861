import { reOrgsConstants } from "./constants";
import api from "./api";
import { defAction } from "../../../helpers/defaultAction";

export const reOrgActions = {
  getOrgs,
  getOrgByName,
  getReOrgByUNP,
  getReOrgByThemesOrSubThemes,
  getReOrgById,
  clearReOrgById,
  getMinReOrgByNameOrUNP,
  getReOrgsForApllicantsList,
  clearMinReOrgByNameOrUNP,
  checkReOrgIsRegistered,
  isRegisteredClear,
  getTypesStatuses,
  isTypesStatusesClear,
  createReOrg,
  deleteReOrg,
  updateReOrg,
  checkIsAvailable,
  checkIsAvailableClear,
};

function getOrgs(params) {
  return (dispatch) => {
    dispatch(request());
    api
      .getOrgs(params)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((e) => {
        dispatch(failure(e));
      });

    function request() {
      return {
        type: reOrgsConstants.SEND_REORGS_REQUEST,
      };
    }
    function success(orgs) {
      return {
        type: reOrgsConstants.SEND_REORGS_SUCCESS,
        payload: orgs,
      };
    }
    function failure(error) {
      return {
        type: reOrgsConstants.SEND_REORGS_FAILURE,
        payload: error,
      };
    }
  };
}

function getOrgByName(params) {
  return (dispatch) => {
    dispatch(request());

    api
      .getOrgByName(params)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((e) => {
        dispatch(failure(e));
      });

    function request() {
      return {
        type: reOrgsConstants.SEND_REORGS_REQUEST,
      };
    }
    function success(orgs) {
      return {
        type: reOrgsConstants.SEND_REORGS_SUCCESS,
        payload: orgs,
      };
    }
    function failure(error) {
      return {
        type: reOrgsConstants.SEND_REORGS_FAILURE,
        payload: error,
      };
    }
  };
}

function getReOrgByThemesOrSubThemes(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetReOrgByThemesOrSubThemes,
    service: {
      func: api.getReOrgByThemesOrSubThemes,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function getReOrgByUNP(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetReOrgByUNP,
    service: {
      func: api.getReOrgByUNP,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function checkIsAvailable(params) {
  const dispatchObj = {
    constants: reOrgsConstants.CheckReOrgIsAvailable,
    service: {
      func: api.CheckReOrgIsAvailable,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function checkIsAvailableClear() {
  return (dispatch) =>
    dispatch({
      type: reOrgsConstants.CheckReOrgIsAvailable.CLEAR,
    });
}

function getReOrgById(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetReOrgById,
    service: {
      func: api.getReOrgById,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function clearReOrgById() {
  return (dispatch) =>
    dispatch({
      type: reOrgsConstants.getReOrgById.CLEAR,
    });
}

function getMinReOrgByNameOrUNP(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetMinReOrgByNameOrUNP,
    service: {
      func: api.getMinReOrgByNameOrUNP,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function getReOrgsForApllicantsList(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetReOrgsForApllicantsList,
    service: {
      func: api.getReOrgsForApllicantsList,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function clearMinReOrgByNameOrUNP() {
  return (dispatch) =>
    dispatch({
      type: reOrgsConstants.GetMinReOrgByNameOrUNP.CLEAR,
    });
}

function checkReOrgIsRegistered(params) {
  const dispatchObj = {
    constants: reOrgsConstants.CheckReOrgIsRegistered,
    service: {
      func: api.CheckReOrgIsRegistered,
      params: params,
    },
  };

  return defAction(dispatchObj);
}

function isRegisteredClear() {
  return (dispatch) =>
    dispatch({
      type: reOrgsConstants.CheckReOrgIsRegistered.CLEAR,
    });
}

function getTypesStatuses(params) {
  const dispatchObj = {
    constants: reOrgsConstants.GetTypesStatuses,
    service: {
      func: api.getTypesStatuses,
      params: params,
    },
  };
  return defAction(dispatchObj);
}

function isTypesStatusesClear() {
  return (dispatch) =>
    dispatch({
      type: reOrgsConstants.GetTypesStatuses.CLEAR,
    });
}

function createReOrg(params) {
  const dispatchObj = {
    constants: reOrgsConstants.Create,
    service: {
      func: api.createReOrg,
      params: params,
    },
  };
  return defAction(dispatchObj);
}

function deleteReOrg(params) {
  const dispatchObj = {
    constants: reOrgsConstants.Delete,
    service: {
      func: api.deleteReOrg,
      params: params,
    },
  };
  return defAction(dispatchObj);
}

function updateReOrg(params) {
  const dispatchObj = {
    constants: reOrgsConstants.Update,
    service: {
      func: api.updateReOrg,
      params: params,
    },
  };
  return defAction(dispatchObj);
}
