export const reAppealTypesConstants = {

	SEND_REAPPEALTYPES_REQUEST : 'SEND_REAPPEALTYPES_REQUEST',
	SEND_REAPPEALTYPES_SUCCESS : 'SEND_REAPPEALTYPES_SUCCESS',
	SEND_REAPPEALTYPES_FAILURE : 'SEND_REAPPEALTYPES_FAILURE',
	SEND_REAPPEALTYPES_CLEAR   : 'SEND_REAPPEALTYPES_CLEAR',

	CreateAppealTypes: {
		REQUEST : 'CREATE_APPEAL_TYPE_REQUEST',
		SUCCESS : 'CREATE_APPEAL_TYPE_SUCCESS',
		FAILURE : 'CREATE_APPEAL_TYPE_FAILURE'
	},
	DeleteAppealTypes: {
		REQUEST : 'DELETE_APPEAL_TYPE_REQUEST',
		SUCCESS : 'DELETE_APPEAL_TYPE_SUCCESS',
		FAILURE : 'DELETE_APPEAL_TYPE_FAILURE'
	},
	UpdateAppealTypes: {
		REQUEST : 'UPDATE_APPEAL_TYPE_REQUEST',
		SUCCESS : 'UPDATE_APPEAL_TYPE_SUCCESS',
		FAILURE : 'UPDATE_APPEAL_TYPE_FAILURE'
	},
	RestoreAppealTypes: {
		REQUEST : 'RESTORE_APPEAL_TYPE_REQUEST',
		SUCCESS : 'RESTORE_APPEAL_TYPE_SUCCESS',
		FAILURE : 'RESTORE_APPEAL_TYPE_FAILURE'
	},
	ClearAppealTypes: {
		CLEAR: 'CLEAR_APPEAL_TYPE'
	}

}