export const reAnswerFormsConstants = {
	GetReAnswerForms: {
		REQUEST : 'GET_RE_ANSWER_FORMS_REQUEST',
		SUCCESS : 'GET_RE_ANSWER_FORMS_SUCCESS',
		FAILURE : 'GET_RE_ANSWER_FORMS_FAILURE',
		CLEAR   : "GET_RE_ANSWER_FORMS_CLEAR"
	},
	CreateAnswerForms: {
		REQUEST : 'CREATE_ANSWER_FORM_REQUEST',
		SUCCESS : 'CREATE_ANSWER_FORM_SUCCESS',
		FAILURE : 'CREATE_ANSWER_FORM_FAILURE'
	},
	DeleteAnswerForms: {
		REQUEST : 'DELETE_ANSWER_FORM_REQUEST',
		SUCCESS : 'DELETE_ANSWER_FORM_SUCCESS',
		FAILURE : 'DELETE_ANSWER_FORM_FAILURE'
	},
	UpdateAnswerForms: {
		REQUEST : 'UPDATE_ANSWER_FORM_REQUEST',
		SUCCESS : 'UPDATE_ANSWER_FORM_SUCCESS',
		FAILURE : 'UPDATE_ANSWER_FORM_FAILURE'
	},
	RestoreAnswerForms: {
		REQUEST : 'RESTORE_ANSWER_FORM_REQUEST',
		SUCCESS : 'RESTORE_ANSWER_FORM_SUCCESS',
		FAILURE : 'RESTORE_ANSWER_FORM_FAILURE'
	},
	getAnswerFormByNamePaged: {
		REQUEST : 'GET_ANSWER_FORM_BY_NAME_PAGED_REQUEST',
		SUCCESS : 'GET_ANSWER_FORM_BY_NAME_PAGED_SUCCESS',
		FAILURE : 'GET_ANSWER_FORM_BY_NAME_PAGED_FAILURE'
	}

	/*
	 * GetReAnswerForm: {
	 *     REQUEST: "GET_REANSWER_FORM_REQUEST",
	 *     SUCCESS: "GET_REANSWER_FORM_SUCCESS",
	 *     FAILURE: "GET_REANSWER_FORM_FAILURE",
	 *     CLEAR: "GET_REANSWER_FORM_CLEAR",
	 * }
	 */
}
